<template>
  <div>
    <b-card
      no-body
      class="mb-0"
    >
      <div>
        <b-row
          v-if="cliente != null"
          class="m-2"
        >
          <!-- Per Page -->
          <b-col
            cols="12"
            md="12"
            class="d-flex align-items-center justify-content-start mb-md-0"
          >
            <h2><strong>{{ cliente.razonSocial }} ({{ cliente.nombreComercial }}) </strong> - {{ cliente.documento }} | {{ cliente.ubigeo.descripcion }} </h2>

          </b-col>
        </b-row>
        <b-row class="m-2">
          <b-col
            cols="12"
            md="12"
          >
            <b-row class="m-2">
              <b-col
                cols="12"
                md="3"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <label>Mostrando</label>
                <v-select
                  v-model="perPage"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="pageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
                />
                <label>registros</label>
              </b-col>

              <b-col
                cols="12"
                md="5"
              >
                <div class="d-flex align-items-center justify-content-end">
                  <b-form-input
                    v-model="query"
                    class="d-inline-block mr-1"
                    placeholder="Buscar..."
                  />
                </div>
              </b-col>
              <b-col
                cols="12"
                sm="2"
              >
                <b-button
                  variant="primary"
                  @click="openModalControlFise('new')"
                >
                  <span class="text-nowrap">Nuevo</span>
                </b-button>
              </b-col>
              <b-col
                cols="12"
                sm="2"
              >
                <b-form-file
                  id="fileToUpload"
                  v-model="fileToUpload"
                  name="fileToUpload"
                  accept="text/xml"
                  placeholder="Seleccione archivo"
                  drop-placeholder="Drop file here..."
                  @change="fileUploaded"
                />
              </b-col>
            </b-row>
            <b-table
              ref="refUserListTable"
              class="position-relative"
              :items="items"
              responsive
              :fields="fields"
              primary-key="id"
              :sort-by.sync="sortBy"
              show-empty
              empty-text="No se encontraron registros"
              :busy="isBusy"
            >
              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner
                    variant="primary"
                    class="align-middle"
                  />
                  <strong class="ml-1">Cargando...</strong>
                </div>
              </template>
              <template #cell(razonSocial)="data">
                <b-media vertical-align="center">
                  <template #aside>
                    <b-avatar
                      size="32"
                      :src="`data:image/jpeg;base64,${data.item.foto}`"
                      :text="avatarText(`${data.item.razonSocial}`)"
                      :variant="`light-${getColorAvatar()}`"
                    />
                  </template>
                  <span class="font-weight-bold d-block text-nowrap">
                    {{ data.item.razonSocial }}
                  </span>
                  <small class="text-muted">{{ data.item.representanteLegal }}</small>
                  <small class="text-muted d-block ">{{ data.item.tipoDocumento.abreviatura }} {{ data.item.documento }}</small>
                </b-media>
              </template>
              <!-- Column: estado_venta  @input="changeStockMinimo(data.item)" -->
              <template #cell(estadoFise)="data">
                <b-badge
                  v-if="data.item.estadoFise == 'PROCESADO'"
                  variant="success"
                >
                  PROCESADO
                </b-badge>
                <b-badge
                  v-if="data.item.estadoFise == 'PENDIENTE'"
                  variant="primary"
                >
                  PENDENTE
                </b-badge>

              </template>
              <template #cell(total)="data">
                <b-form-input
                  v-model="data.item.total"
                  :disabled="data.item.estadoFise == 'PROCESADO'"
                  style="max-width: 120px;"
                  @keyup.enter="getTotalFise(data.item)"
                />
              </template>
              <template #cell(montoFise)="data">
                <b-form-input
                  v-model="data.item.montoFise"
                  :disabled="data.item.estadoFise == 'PROCESADO'"
                  style="max-width: 120px;"
                  @keyup.enter="getMontoFise(data.item)"
                />
              </template>
              <template #cell(codigo)="data">
                <b-form-input
                  v-model="data.item.codigo"
                  :disabled="data.item.estadoFise == 'PROCESADO'"
                  style="max-width: 120px;"
                  @keyup.enter="getCodigoFise(data.item,data.index)"
                />
              </template>
              <template #cell(nombreBeneficiario)="data">
                <!-- <b-form-input
                  v-model="data.item.nombreBeneficiario"
                  style="font-size: 12px;"
                  :disabled="true"
                /> -->
                <div class="d-flex align-items-center">
                  <div>
                    <div class="font-small-4 font-weight-bolder">
                      {{ data.item.nombreBeneficiario }}
                    </div>
                    <div class="font-small-3 text-muted">
                      {{ data.item.direccionBeneficiario }}
                    </div>
                  </div>
                </div>
              </template>
              <template #cell(dniBeneficiario)="data">
                <b-form-input
                  v-model="data.item.dniBeneficiario"
                  :disabled="data.item.estadoFise == 'PROCESADO'"
                  style="max-width: 100px;"
                  @keyup.enter="getSearchDocumentCliente(data.item)"
                />
              </template>
              <template #cell(productoTemplate)="data">
                <v-select
                  v-model="data.item.producto"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="descripcion"
                  :disabled="data.item.estadoFise == 'PROCESADO'"
                  :options="selectProducto"
                  style="font-size: 11px;"
                  @input="changeProductoFise(data)"
                />
              </template>
              <template #cell(direccionBeneficiario)="data">
                <div>{{ data.item.direccionBeneficiario }}</div>
              </template>
              <!-- Column: Actions -->
              <template #cell(acciones)="data">
                <b-dropdown
                  variant="link"
                  no-caret
                  :right="$store.state.appConfig.isRTL"
                >

                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="align-middle text-body"
                    />
                  </template>
                  <b-dropdown-item
                    v-if="data.item.estadoFise == 'PENDIENTE'"
                    @click="getProcesar(data)"
                  >
                    <feather-icon icon="ClipboardIcon" />
                    <span class="align-middle ml-50">Procesar</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="data.item.estadoFise == 'PENDIENTE'"
                    @click="getDelete(data)"
                  >
                    <feather-icon icon="TrashIcon" />
                    <span class="align-middle ml-50">Eliminar</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="data.item.estadoFise == 'PROCESADO' && data.item.generaVenta !== null"
                    target="_blank"
                    :href="data.item.generaVenta.repuestaOse == null ?'':data.item.generaVenta.repuestaOse.rutaPdf"
                  >
                    <feather-icon icon="ClipboardIcon" />
                    <span class="align-middle ml-50">{{ data.item.generaVenta.numeroComprobante }}</span>
                  </b-dropdown-item>

                  <b-dropdown-item
                    v-if="data.item.estadoFise == 'PROCESADO' && data.item.generaVenta == null"
                    @click="getFacturarVale(data)"
                  >
                    <feather-icon icon="ClipboardIcon" />
                    <span class="align-middle ml-50">Boletear</span>
                  </b-dropdown-item>
                </b-dropdown>
              </template>

            </b-table>
            <div>
              <b-row class="mx-2 mb-2">

                <b-col
                  cols="12"
                  sm="6"
                  class="d-flex align-items-center justify-content-center justify-content-sm-start"
                >
                  <span class="text-muted">Mostrando {{ from }} a {{ to }} de {{ of }} registros</span>
                </b-col>
                <!-- Pagination -->
                <b-col
                  cols="12"
                  sm="6"
                  class="d-flex align-items-center justify-content-center justify-content-sm-end"
                >

                  <b-pagination
                    v-model="page"
                    :total-rows="totalItems"
                    :per-page="perPage"
                    first-number
                    last-number
                    class="mb-0 mt-1 mt-sm-0"
                    prev-class="prev-item"
                    next-class="next-item"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>

                </b-col>

              </b-row>
            </div>
          </b-col>
        </b-row>

      </div>
    </b-card>
    <b-modal
      ref="my-modal"
      size="lg"
      hide-footer
      title=""
      no-close-on-backdrop
      no-close-on-esc
      style="max-width: 90%;"
    >
      <div class="d-block">
        <h4 class="text-center">
          NUEVA VALE FISE</h4>
        <b-card
          class="card-transaction"
          no-body
        >
          <b-card-body>
            <b-form
              class="p-2"
              @submit.prevent="onSubmit"
            >
              <b-row>
                <b-col
                  cols="12"
                  md="10"
                  class="mb-1 mb-md-0"
                >
                  <b-form-group
                    label="Cliente Final"
                    label-for="clienteFinal"
                  >
                    <v-select
                      v-model="clienteFinal"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="nombreComercial"
                      :options="selectClienteFinal"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="2"
                  class="mb-1 mb-md-0"
                >
                  <b-form-group
                    label="cantidad Vale"
                    label-for="cantidad Vale"
                  >
                    <b-form-input
                      v-model="cantidadVale"
                      type="number"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row
                class="text-center"
                align-v="center"
              >
                <b-col
                  cols="12"
                  md="4"
                />
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-button
                    variant="primary"
                    class="btn-icon"
                    type="submit"
                    size="lg"
                  >
                    Guardar
                    <feather-icon icon="SaveIcon" />
                  </b-button>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                />
              </b-row>
            </b-form>
          </b-card-body>
        </b-card>
      </div>
    </b-modal>
    <b-modal
      ref="my-modal-procesado"
      size="custom"
      hide-footer
      title=""
      no-close-on-backdrop
      no-close-on-esc
    >
      <div class="d-block">
        <h4 class="text-center">
          VALES PROCESADOS IMPORTADOS</h4>
        <b-card
          class="card-transaction"
          no-body
        >
          <b-card-body>
            <b-form
              class="p-2"
              @submit.prevent="onSubmitProcesado"
            >
              <b-row>
                <b-col
                  cols="12"
                  md="12"
                  class="mb-1 mb-md-0"
                >
                  <b-table
                    responsive
                    :items="listValeProcesado"
                    :fields="fieldsValeProcesado"
                    class="mb-0"
                  >
                    <template #cell(nombreBeneficiario)="data">
                      <div class="d-flex align-items-center">
                        <div>
                          <div class="font-small-4 font-weight-bolder">
                            {{ data.item.nombreBeneficiario }}
                          </div>
                          <div class="font-small-3 text-muted">
                            {{ data.item.direccionBeneficiario }}
                          </div>
                        </div>
                      </div>
                    </template>
                    <template #cell(acciones)="data">
                      <div class="text-nowrap">
                        <b-button
                          size="sm"
                          variant="danger"
                          @click="eliminarValeProcesado(data)"
                        >
                          <feather-icon icon="TrashIcon" />
                          <span class="align-middle ml-50">Eliminar</span>
                        </b-button>
                      </div>
                    </template>
                  </b-table>
                </b-col>
              </b-row>
              <b-row
                class="text-center"
                align-v="center"
              >
                <b-col
                  cols="12"
                  md="4"
                />
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-button
                    variant="success"
                    class="btn-icon"
                    type="submit"
                    size="lg"
                  >
                    Procesar
                    <feather-icon icon="SaveIcon" />
                  </b-button>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                />
              </b-row>
            </b-form>
          </b-card-body>
        </b-card>
      </div>
    </b-modal>
  </div>
</template>

<script>

import {
  BCard, BRow, BCol, BFormInput, BTable, BDropdown, BDropdownItem, BPagination, BSpinner, BAvatar, BMedia, VBTooltip, BBadge, BButton, BFormGroup, BForm, BCardBody, BFormFile,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { showToast, showError } from '@/helpers'
import { avatarText } from '@core/utils/filter'
import moment from 'moment'
// import formValidation from '@core/comp-functions/forms/form-validation'
import { required } from '@validations'
// import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    // BButton,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    BBadge,

    vSelect,
    BSpinner,
    BAvatar,
    BMedia,
    // BCardText,
    BButton,
    BFormGroup,
    BForm,
    BCardBody,
    BFormFile,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },

  data: () => ({
    isBusy: false,
    isBusyFacturacion: false,
    rangeDate: null,
    idEmpresa: 3,
    isToggleSidebar: false,
    dataEdit: {},
    formType: '',
    page: 1,
    perPage: 10,
    totalRows: 0,
    totalItems: 0,
    totalPage: 0,
    from: 0,
    to: 0,
    of: 0,
    pageOptions: [10, 20, 50],
    sortBy: null,
    cliente: null,
    sortDesc: false,
    permisoAlmacen: false,
    isProducto: false,
    sortDirection: 'asc',
    query: '',
    queryFacturacion: '',
    totalAll: '',
    items: [],
    selectProducto: [],
    statusFilter: { id: '-1', descripcion: 'Todos' },
    statusOptions: [
      { id: '-1', descripcion: 'Todos' },
      { id: '1', descripcion: 'Enviados' },
      { id: '2', descripcion: 'Pendientes' },
      { id: '3', descripcion: 'Vigentes' },
      { id: '4', descripcion: 'Anulados' },
    ],
    userProfile: localStorage.getItem('userProfile'),
    fields: [
      { key: 'idControlFise', sortable: true, thClass: 'd-none', tdClass: 'd-none' },
      { key: 'fecha', label: 'FECHA', sortable: false, thStyle: { width: '5%' } },
      // { key: 'cliente.razonSocial', label: 'CLIENTE', sortable: false, thStyle: { width: '10%' } },
      { key: 'codigo', label: 'CODIGO', sortable: false, thStyle: { width: '10%' } },
      { key: 'nombreBeneficiario', label: 'BENEFICIARIO', sortable: false, thStyle: { width: '20%' } },
      { key: 'dniBeneficiario', label: 'DNI', sortable: false, thStyle: { width: '10%' } },
      { key: 'productoTemplate', label: 'PRODUCTO', sortable: false, thStyle: { width: '20%' } },
      // { key: 'nroComprobante', label: 'Nro Comprobante' },
      // { key: 'direccionBeneficiario', label: 'DIRECCION', sortable: false, thStyle: { width: '11%' } },
      { key: 'estadoFise', label: 'ESTADO', sortable: false, thStyle: { width: '5%' } },
      { key: 'montoFise', label: 'MONTO FISE', sortable: false, thStyle: { width: '6%' } },
      { key: 'total', label: 'TOTAL', sortable: false, thStyle: { width: '6%' } },
      { key: 'acciones', thStyle: { width: '3%' } },
      // { key: 'acciones' },
    ],
    fieldsValeProcesado: [
      { key: 'fecha', label: 'FECHA', sortable: false, thStyle: { width: '5%' } },
      { key: 'nombreBeneficiario', label: 'BENEDIFICIARIO', sortable: false, thStyle: { width: '20%' } },
      { key: 'dniBeneficiario', label: 'DNI', sortable: false, thStyle: { width: '4%' } },
      { key: 'codigo', label: 'CODIGO', sortable: false, thStyle: { width: '4%' } },
      { key: 'montoFise', label: 'IMPORTE', sortable: false, thStyle: { width: '4%' } },
      { key: 'acciones', label: 'ACCIONES', thStyle: { width: '3%' } },
    ],
    formData: null,
    subTotal: 0,
    selectTipoDocumento: [],
    getValidationState: null,
    refFormObserver: null,
    resetForm: null,
    selectSerie: [],
    required,
    pageFacturacion: 1,
    perPageFacturacion: 10,
    totalRowsFacturacion: 0,
    totalItemsFacturacion: 0,
    totalPageFacturacion: 0,
    fromFacturacion: 0,
    toFacturacion: 0,
    ofFacturacion: 0,
    resumenControlFise: [],
    sumatoriaControlFise: 0,
    selectClienteFinal: [],
    clienteFinal: null,
    cantidadVale: 0,
    fileToUpload: null,
    listValeProcesado: [],
  }),
  watch: {
    perPage: {
      handler() {
        this.listarRegistros()
      },
    },
    page: {
      handler() {
        this.listarRegistros()
      },
    },
    query: {
      handler() {
        this.listarRegistros()
      },
    },
  },
  //   created() {
  //     const formValidationMethods = formValidation(this.resetData)
  //     this.refFormObserver = formValidationMethods.refFormObserver
  //     this.getValidationState = formValidationMethods.getValidationState
  //     this.resetForm = formValidationMethods.resetForm
  //   },
  mounted() {
    this.listarRegistros()
  },
  methods: {
    getPrintPdf(data) {
      if (data.item.repuestaOse !== null) {
        console.log(data.item.repuestaOse.rutaPdf)
      }
    },
    async listarRegistros() {
      // const dataUser = JSON.parse(localStorage.getItem('userData'))
      this.isProducto = false
      this.isBusy = true
      this.selectProducto = []
      await store
        .dispatch('comercial/CONTROL_FISE_FIND_ALL', {
          limit: this.perPage,
          query: this.query,
          page: this.page,
          sortBy: '',
          venta: '-1',
          cliente_id: this.$route.params.clienteId,
          tipo: 'FISE',
        })
        .then(response => {
          this.items = response.items
          this.totalRows = response.totalRows
          this.totalItems = response.totalItems
          this.totalPage = response.totalPage
          this.from = this.perPage * (this.page - 1) + (this.totalRows ? 1 : 0)
          this.to = this.perPage * (this.page - 1) + this.totalRows
          this.of = this.totalItems
          this.items.forEach((element, index) => {
            if (index === 0) {
              this.cliente = element.cliente
              this.almacen = element.origenVenta
            }
            this.items[index].isActive = false
            this.items[index].producto = { idProducto: element.idProducto, descripcion: element.detalle }
          })
          response.producto.forEach(element => {
            const rowProcuto = element
            rowProcuto.descripcion = `${rowProcuto.presentacion.descripcion} - ${rowProcuto.presentacion.marca.descripcion}`
            this.selectProducto.push(rowProcuto)
          })
          this.selectClienteFinal = []
          this.selectClienteFinal = response.cliente
          // response.cliente.forEach(rowCliente => {
          //   if (rowCliente.idCliente === this.$route.params.clienteId) {
          //     this.clienteFinal = rowCliente
          //   }
          // })

          this.clienteFinal = this.cliente
          this.items = this.items.filter(item => item.tipo === 'FISE')
          this.sumatoriaControlFise = response.sumatoria
          this.isProducto = true
          // this.almacen = response.items[0]
        })
        .catch(error => {
          this.showToast('Ha ocurrido un error', error, 'danger')
        })
      this.isBusy = false
    },

    formatearMoneda(valor) {
      // Convertir el número en cadena con dos decimales
      const valorFormateado = valor.toFixed(2)
      const respose = `${valorFormateado}`
      //   if (moneda === 'DOLARES') {
      //     respose = `$/. ${valorFormateado}`
      //   }
      return respose
      // Agregar el prefijo "S/." y retornar
    },
    async changeProductoFise(data) {
      console.log(data)
      const newData = { ...data.item }
      // Asignar los nuevos valores a la copia
      newData.idProducto = data.item.producto.idProducto
      newData.detalle = data.item.producto.descripcion
      newData.total = data.item.producto.precio
      await store
        .dispatch('comercial/CONTROL_FISE_UPDATE', newData)
        .then(rp => {
          console.log(rp)
          this.listarRegistros()
          this.showToast('Registro', 'Se actualizo el producto', 'success')
        })
        .catch(error => {
          this.showToast('Ha ocurrido un error', error, 'danger')
        })
    },
    deleteVenta(data) {
      this.$swal({
        title: 'Está seguro?',
        text: 'Va eliminar un registro!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí',
        cancelButtonText: 'No',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          const partComprobante = data.item.numeroComprobante.split('-')
          const params = { nro_comprobante: partComprobante[1], serie: partComprobante[0], tipo_documento: data.item.tipoDocumento.tipoDocumentoId, venta_id: data.item.ventaId }
          this.$swal({
            title: 'Anulando en la Ose',
            showCancelButton: false,
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            onOpen: async () => {
              this.$swal.showLoading()
              const service = 'comercial/VENTA_DELETE'
              await store.dispatch(service, params)
                .then(() => {
                  // this.items.forEach((item, index) => {
                  //   if (item.ventaId === data.item.ventaId) {
                  //     this.items[index].repuestaOse = response.respuesta_ose
                  //   }
                  // })
                  this.listarRegistros()
                  this.$swal.close()
                  this.showToast('Operación Extitosa', 'El comprobante se anulo', 'success')
                })
                .catch(error => {
                  this.$swal.close()
                  console.log(error)
                  this.showToast('Operación Error', error.errorMessage, 'danger')
                })
            },
          })
        }
      })
    },
    refetchData(response) {
      let message = 'El proceso se ha ejecutado satisfactoriamente.'
      if (response.message) {
        message = response.message
      }
      this.showToast('Operación Extitosa', message, 'success')
      this.listarRegistros()
    },
    getColorAvatar() {
      const max = 7
      const min = 1
      const status = Math.floor(Math.random() * ((max + 1) - min) + min)
      if (status === 1) return 'primary'
      if (status === 2) return 'danger'
      if (status === 3) return 'secondary'
      if (status === 4) return 'warning'
      if (status === 5) return 'info'
      if (status === 6) return 'success'
      return 'primary'
    },
    // deleteVenta
    // generarGuia
    // generarNota
    // getConsultaOse
    chageStatusFilter() {
      console.log(this.statusFilter)
      this.listarRegistros()
    },
    async getSearchDocumentCliente(data) {
      // this.isBusy = true
      if (data.dniBeneficiario.length < 8 || data.dniBeneficiario.length > 8) {
        this.showToast('Operación Error', 'Tiene que ingresar 8 digitos del dni', 'danger')
        return
      }
      await store
        .dispatch('comercial/CONSULTA_DNI_FIND_ALL', {
          documento: data.dniBeneficiario,
        })
        .then(async response => {
          let dataUpdate = {}
          this.items.forEach((item, index) => {
            if (item.idControlFise === data.idControlFise) {
              this.items[index].dniBeneficiario = response.data.documento
              this.items[index].nombreBeneficiario = response.data.nombreComercial
              this.items[index].direccionBeneficiario = response.data.direccion
              dataUpdate = this.items[index]
            }
          })
          await store
            .dispatch('comercial/CONTROL_FISE_UPDATE', dataUpdate)
            .then(rp => {
              console.log(rp)
              this.listarRegistros()
              this.showToast('Registro', 'Se actualizo el beneficiario', 'success')
            })
            .catch(error => {
              this.showToast('Ha ocurrido un error', error, 'danger')
            })
        })
        .catch(error => {
          this.showToast('Operación Error', error.errorMessage, 'danger')
        })
        // this.isBusy = false
    },
    async getCodigoFise(data, index) {
      if (data.codigo.length !== 11) {
        this.showToast('Operación Error', 'Tiene que ingresar 11 digitos del codigo', 'danger')
        return
      }
      const dataFise = data
      await store
        .dispatch('comercial/CONTROL_FISE_UPDATE', dataFise)
        .then(rp => {
          console.log(rp)
          this.showToast('Registro', 'Se actualizo el codigo', 'success')
        })
        .catch(error => {
          console.log(error)
          this.showToast('Ha ocurrido un error', 'EL codigo ya fue registrado', 'danger')
          this.items[index].codigo = ''
          console.log(this.items[index])
        })
    },
    async getNroComprobanteFise(tipoDocumentoId) {
      let numeroDocumento = ''
      await store.dispatch('comercial/GET_SERIES', { tipo_documento_id: tipoDocumentoId,
      })
        .then(async response => {
          let listSerie = []
          if (response) {
            listSerie = response
          }
          let serieId = 0
          listSerie.forEach((element, index) => {
            if (index === 0) {
              serieId = element
            }
          })
          await store.dispatch('comercial/GET_NRO_COMPROBANTE', { tipo_documento_id: tipoDocumentoId, serie_id: serieId.serieId,
          })
            .then(rp => {
              // Vue.swal.close()
              if (rp) {
                // console.log(formData.value.nro_comprobante)
                numeroDocumento = rp.nroDocumento
                // console.log(formData.value.nro_comprobante)
              }
            })
        })
      return numeroDocumento
    },
    async formParseVenta(data) {
      // console.log(data)
      const form = {
        sunatVenta: {},
        detalleVentas: [],
      }
      // idboleta = 2
      const nroDocumento = await this.getNroComprobanteFise(2)
      const sunatVenta = {
        almacen: data.origenVenta,
        clienteDato: `${data.nombreBeneficiario}|${data.direccionBeneficiario}|${data.dniBeneficiario}`,
        clienteId: 0,
        email: '',
        envio: true,
        estado: true,
        // fechaVenta: moment().format('YYYY-MM-DD'),
        fechaVencimiento: moment().format('YYYY-MM-DD'),
        formatoImpresion: 'A4',
        formatoPago: { formaPagoId: 1 },
        igv: 0,
        latitud: 'oficina',
        longitud: 'oficina',
        montoIgv: 0,
        numeroComprobante: nroDocumento,
        observacion: `VALE FISE: ${data.codigo}`,
        sub_total: data.total,
        tipoDocumento: { tipoDocumentoId: 2 },
        tipoMoneda: { tipoMonedaId: 1 },
        tipoVenta: { tipoVentaId: 2 },
        total: data.total,
        vendedorId: 0,
        ventaId: 0,
        tipoPago: 'COMPLETO',
        cantidadFise: 0,
      }
      form.sunatVenta = sunatVenta
      // items.value.forEach(element => {
      //  console.info('DETALLE ROW', element)
      const row = {
        cantidad: 1,
        detalleVentaId: 1,
        estado: true,
        igv: 0,
        observacion: '',
        precio: data.total,
        productoDato: data.detalle,
        productoId: data.idProducto,
        subTotal: data.total,
        sunatVenta: { ventaId: 0 },
        tipo: 'VENDIDO',
        unidadMedidaDato: 'NIU',
        unidadMedidaId: 0,
      }
      form.detalleVentas.push(row)
      // })
      // console.log(JSON.stringify(form))
      return (form)
    },
    async getTotalFise(data) {
      const dataFise = data
      await store
        .dispatch('comercial/CONTROL_FISE_UPDATE', dataFise)
        .then(rp => {
          console.log(rp)
          this.listarRegistros()
          this.showToast('Registro', 'Se actualizo el Total', 'success')
        })
        .catch(error => {
          this.showToast('Ha ocurrido un error', error, 'danger')
        })
    },
    async getFacturarVale(data) {
      console.log(data)
      this.$swal({
        title: 'Registrando la venta',
        showCancelButton: false,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        onOpen: async () => {
          this.$swal.showLoading()
          const service = 'comercial/VENTA_CREATE'
          // formData.value.presentacion = presentacionSel.value
          const formVenta = await this.formParseVenta(data.item)
          await store.dispatch(service, formVenta)
            .then(response => {
              this.$swal.close()
              this.showToast('Registro', response.message, 'success')
            })
            .catch(error => {
              this.$swal.close()
              console.log(error)
              throw new Error(error)
            })
        },
      })
    },
    getProcesar(data) {
      const fise = data.item
      try {
        if (fise.codigo === '' || fise.codigo === null) {
          throw new Error('TIENE QUE INGRESAR EL CODIGO')
        }
        if (fise.dniBeneficiario === '' || fise.dniBeneficiario === null) {
          throw new Error('TIENE QUE INGRESAR EL BENEFICIARIO')
        }
        if (fise.nombreBeneficiario === '' || fise.nombreBeneficiario === null) {
          throw new Error('TIENE QUE INGRESAR EL BENEFICIARIO')
        }
        if (fise.montoFise === 0 || fise.montoFise === null) {
          throw new Error('TIENE QUE INGRESAR EL MONTO FISE')
        }
        // UPDATE PROCESO DE VALE FISE
        // fise.generaVenta = response.result
        fise.estadoFise = 'PROCESADO'
        this.getTotalFise(fise)
      } catch (error) {
        console.log(error)
        this.$swal.close()
        this.showToast('Ha ocurrido un error', error.message, 'danger')
      }
    },
    async getMontoFise(data) {
      const dataFise = data
      await store
        .dispatch('comercial/CONTROL_FISE_UPDATE', dataFise)
        .then(rp => {
          console.log(rp)
          this.listarRegistros()
          this.showToast('Registro', 'Se actualizo el Total', 'success')
        })
        .catch(error => {
          this.showToast('Ha ocurrido un error', error, 'danger')
        })
    },

    async getSearchDocument(tDocumento) {
      // this.isBusy = true
      await store
        .dispatch('comercial/CLIENTE_SEARCH_DOCUMENTO', {
          documento: this.formData.documentoCliente,
          tipo_documento: tDocumento,
        })
        .then(response => {
          console.log(response)
          this.formData.razon_social = response.data.razonSocial
          this.formData.direccion = response.data.direccion
        })
        .catch(error => {
          console.log(error)
          this.showToast('Ha ocurrido un error', error.message, 'danger')
        })
      // this.isBusy = false
    },
    openModalControlFise(tipo) {
      console.log(tipo)
      this.cantidadVale = 0
      // resumenControlFise
      this.clienteFinal = null
      // console.log(this.almacen)
      this.$refs['my-modal'].show()
    },
    onSubmit() {
      try {
        console.log(this.clienteFinal)
        console.log(this.cantidadVale)
        if (this.clienteFinal === null) {
          throw new Error('TIENE QUE SELECIONAR UN CLIENTE')
        }
        if (this.cantidadVale === 0 || this.cantidadVale === null) {
          throw new Error('TIENE QUE INGRESAR LAS CANTIDADES DE VALES')
        }
        this.$swal({
          title: 'Registrando los vales fise',
          showCancelButton: false,
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
          onOpen: async () => {
            this.$swal.showLoading()
            const service = 'comercial/CONTROL_FISE_MASIVO_CREATE'
            // formData.value.presentacion = presentacionSel.value
            const formVenta = { cantidad: this.cantidadVale, cliente_id: this.clienteFinal.idCliente }
            await store.dispatch(service, formVenta)
              .then(response => {
                this.$swal.close()
                this.$refs['my-modal'].hide()
                this.showToast('Registro', response.message, 'success')
                this.listarRegistros()
              })
              .catch(error => {
                this.$swal.close()
                console.log(error)
                throw new Error(error)
              })
          },
        })
      } catch (error) {
        console.log(error)
        this.$swal.close()
        this.showToast('Ha ocurrido un error', error.message, 'danger')
      }
    },
    async fileUploaded(event) {
      this.$swal({
        title: 'Extrayendo Informacion',
        showCancelButton: false,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        onOpen: async () => {
          this.$swal.showLoading()
          const file = event.target.files[0]
          console.log(file)
          this.listValeProcesado = []
          const file1 = document.getElementById('fileToUpload').files[0]
          const reader = new FileReader()
          reader.readAsText(file1)
          reader.onloadend = async () => {
            const parser = new DOMParser()
            const doc = parser.parseFromString(reader.result, 'application/xml')
            // const nroComprobante = doc.getElementsByTagName('cbc:ID')[0].innerHTML
            // InvoiceLine
            // const invoiceLine = doc.querySelectorAll('sms')
            const invoiceLine = Array.from(doc.querySelectorAll('sms'))
            // const listProcesado = []
            const promises = invoiceLine.map(async rowInvoiceLine => {
              const messageData = {
                protocol: rowInvoiceLine.getAttribute('protocol'),
                address: rowInvoiceLine.getAttribute('address'),
                date: rowInvoiceLine.getAttribute('date'),
                type: rowInvoiceLine.getAttribute('type'),
                subject: rowInvoiceLine.getAttribute('subject'),
                body: rowInvoiceLine.getAttribute('body'),
                service_center: rowInvoiceLine.getAttribute('service_center'),
                readable_date: rowInvoiceLine.getAttribute('readable_date'),
                contact_name: rowInvoiceLine.getAttribute('contact_name'),
              }

              const partBody = messageData.body.split('\n')
              if (partBody.length === 4 && partBody[0] === 'El cupon se proceso correctamente.') {
                const dni = partBody[1].replace('DNI: ', '').replace('.', '')
                const cupon = partBody[2].replace('Cupon: ', '')
                const importe = partBody[3].replace('Importe: S/. ', '')
                console.info(dni, cupon, importe)
                const dateInMilliseconds = parseInt(messageData.date, 10)
                const date = new Date(dateInMilliseconds)
                try {
                  const response = await store.dispatch('comercial/CONSULTA_DNI_FIND_ALL', { documento: dni })
                  // return response.data // Retorna el resultado
                  // console.log(response)
                  // moment(messageData.date).format('DD/MM/YYYY HH:mm:ss')

                  const rowProcesado = { nombreBeneficiario: response.data.razonSocial, direccionBeneficiario: response.data.direccion, dniBeneficiario: dni, codigo: cupon, montoFise: importe, fecha: moment(date).format('DD/MM/YYYY HH:mm:ss'), cliente: { idCliente: this.$route.params.clienteId }, total: 50, detalle: this.selectProducto[0].descripcion, idProducto: this.selectProducto[0].idProducto, montoConsumido: 0 }
                  // listProcesado.push(rowProcesado)
                  return rowProcesado
                } catch (error) {
                  console.error('Error en la consulta de DNI:', error)
                  const rowProcesado = { nombreBeneficiario: 'CLIENTE VARIOS', direccionBeneficiario: '-', dniBeneficiario: dni, codigo: cupon, montoFise: importe, fecha: moment(date).format('DD/MM/YYYY HH:mm:ss'), cliente: { idCliente: this.$route.params.clienteId }, total: 50, detalle: this.selectProducto[0].descripcion, idProducto: this.selectProducto[0].idProducto, montoConsumido: 0 }

                  return rowProcesado
                }
              }
              return null // Si no cumple la condición, devolver null
            })

            const results = await Promise.all(promises) // Espera todas las peticiones
            // results.filter(persona => persona !== null ? this.listValeProcesado.add(persona))
            results.forEach(element => {
              if (element !== null) {
                this.listValeProcesado.push(element)
              }
            })
            console.log(this.listValeProcesado)
            this.fileToUpload = null
            this.$refs['my-modal-procesado'].show()
          }
          this.$swal.close()
        },
      })
    },
    eliminarValeProcesado(data) {
      this.listValeProcesado.splice(data.index, 1)
    },
    onSubmitProcesado() {
      console.log(this.listValeProcesado)
      this.$swal({
        title: 'Registrando los vales fise',
        showCancelButton: false,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        onOpen: async () => {
          this.$swal.showLoading()
          const service = 'comercial/CONTROL_FISE_PROCESADO_MASIVO_CREATE'
          // formData.value.presentacion = presentacionSel.value
          await store.dispatch(service, { vales: this.listValeProcesado })
            .then(response => {
              this.$swal.close()
              this.$refs['my-modal-procesado'].hide()
              this.showToast('Registro', response.message, 'success')
              this.listarRegistros()
            })
            .catch(error => {
              this.$swal.close()
              console.log(error)
              throw new Error(error)
            })
        },
      })
    },
    avatarText,
    showToast,
    showError,
  },

}
</script>

  <style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }

  .invoice-filter-select {
    min-width: 190px;

    ::v-deep .vs__selected-options {
      flex-wrap: nowrap;
    }

    ::v-deep .vs__selected {
      width: 100px;
    }
  }

  </style>

  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  </style>

  <style>
  .w200{
    width: 350px !important;
  }
  .modal-custom {
        max-width: 60%;
    }

  </style>
  <style lang="scss">
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
  </style>
